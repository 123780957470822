<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            header-component
                v-tooltip(left)
                    template(slot="activator")
                        v-btn.no-margin(absolute dark fab top right color='primary' @click.stop="drawerRight = !drawerRight")
                            v-icon search
                    span {{ $t('Filtrar resultados') }}
            v-navigation-drawer.filters.grey.darken-3(fixed v-model="drawerRight" right app width="246")
                v-form
                    v-toolbar.grey.darken-1(flat)
                        v-text-field(v-model="filter.query" :placeholder="$t('Buscar...')" dark)
                        v-icon(dark) search
                    .filters-content
                        .white--text.mb-2 {{$t('Filtrar por')}}:
                        v-select.mb-3(
                            v-model="filter.networks"
                            :items='networks'
                            item-text="name"
                            item-value="uuid"
                            :label='$t("Red")'
                            append-icon="keyboard_arrow_down" 
                            autocomplete
                            :filter="vFilter"
                            dark
                            multiple
                            color="white"
                        )
                        .btns.btns-right
                            v-btn(@click="cleanFilter" color="primary") {{$t('Limpiar')}}
            v-content
                v-snackbar(
                    :timeout="3000",
                    :top="true"
                    :right="true"
                    :color="resultColor"
                    v-model="resultMsj")
                    | {{ resultText }}
                    v-btn(flat dark @click="resultMsj = false") {{$t('cerrar')}}
                v-container.grid-list-xl(fluid)
                    h1 {{$t('Resultados')}}
                    template(v-if="preload")
                        h3 {{$t('Cargando')}}...
                    template(v-else)
                        v-layout(row wrap v-if="results.length")
                            v-flex(xs12 sm6 md4 lg3 xl2 v-for="result in results" :key="results.uuid")
                                .sheet.grey.lighten-3
                                    .sheet-options
                                        v-menu(left)
                                            v-icon(color='primary' dark slot='activator') more_vert
                                            v-list.sheet-menu
                                                v-list-tile.white--text(@click="$refs.results.open(result)")
                                                    v-list-tile-title {{ $t('Resultados') }}
                                    .sheet-title
                                        h2.primary--text {{ result.name }}
                                    .sheet-content
                                        template(v-if="result.results")
                                            b.d-block {{ $t('Promedio de respuestas correctas: ') }}
                                            h2.my-0 {{ result.results.avg }}/5 {{ $t('preguntas') }}
                                            v-progress-linear.mb-4(:value="result.results.avg * 5" :buffer-value="(5*5)" color="green")
                                            b.d-block {{ $t('Porcentaje de respuestas correctas: ') }}
                                            v-progress-circular.mt-2(:size="100" :value="result.results.per" color="green")
                                                h2 {{ result.results.per }}%
                            v-flex(xs12)
                                infinite-loading(:infinite="infinite" ref="infinite")
                        .not-found(v-else)
                            h3 {{ $t('No se encontraron resultados') }}
        results(ref="results")
</template>

<script>

    import auth from 'mixins/auth'
    import _ from 'lodash/debounce'
    import _m from 'lodash/merge'
    import Results from 'components/callcenter/Results'

    export default {
        components: {
            Results
        },
        mixins: [auth], 
        metaInfo() {
            return {
                title: this.$t('Resultados de Callcenter') + ' - Wappid'
            }
        },
        data(){
            return {

                // Loader
                preload   : false,
                pagination: {
                    page: 1,
                    rowsPerPage: 20
                },
                total     : 0,
                loading   : false,
                cancelFilter: null,
                limit : this.$root.limit,
                offset: 0,

                // Filtros
                drawerRight   : false,
                modalDateStart: false,
                modalDateEnd  : false,
                filter        : {},
                filterSchema  : {
                    query     : '',
                    networks  : []
                },

                // Listas
                users         : [],
                results       : [],
                networks      : [],
                loadingBtn    : false,
                headers: [
                    {
                        text: 'Teléfono',
                        align: 'left',
                        value: 'phone',
                        sortable: false
                    },
                    {
                        text: 'Opciones',
                        align: 'left',
                        value: 'options',
                        sortable: false
                    },
                    {
                        text: 'Red',
                        align: 'left',
                        value: 'network',
                        sortable: false
                    }
                ],

                // Mensajes
                resultMsj  : false,
                resultColor: '',
                resultText : '',
            }
        },
        watch: {
            filter: {
                handler(){

                    this.preload = true
                    this.results = []

                    this.fetchNetworResult()
                },
                deep: true
            }
        },
        created(){
            this.filter = _m({}, this.filterSchema)
            this.fetchNetwork()
        },
        methods: {

            fetchNetworResult: _(function(infinite = false){

                if(infinite){
                    this.offset += this.limit
                }else{
                    this.results = []
                    this.offset = 0
                    this.$refs.infinite && this.$refs.infinite.reset()
                }

                let filter = {
                    limit : this.limit,
                    offset: this.offset,
                    filter: JSON.stringify(this.filter)
                }

                this.$Progress.start()

                let url = '/callcenter/result/network'

                this.$api(this, (xhr) => {
                    xhr.get(url, { params: filter }).then((r) => {

                        let data = r.data
                        if(!infinite) this.preload = false
                        this.$Progress.finish()

                        if(infinite){
                            if(data.response){
                                this.results = this.results.concat(data.data)
                                this.$refs.infinite && this.$refs.infinite.loaded()
                            }else{
                                this.$refs.infinite && this.$refs.infinite.complete()
                            }
                        }else{
                            this.results = (data.response ? data.data : [])
                        }

                        this.$nextTick(() => {
                            this.isFilter = false
                        })

                    }).catch(() => {
                        
                        if(!infinite) this.preload = false
                        this.isFilter  = false
                        this.$Progress.finish()

                        this.catchError('result')
                    })
                })

            }, 500),

            fetchNetwork(){

                this.networks = []
                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/callcenter/network').then((r) => {

                            let data = r.data
                            if(data.response){
                                this.networks = data.data
                            }
                            resolve()

                        }).catch(() => {
                            resolve()
                        })
                    })
                })
            },

            infinite(){
                setTimeout(() => this.fetchNetworResult(true) , 500)
            },
        }
    }

</script>