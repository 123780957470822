<template lang="pug">
    div
        v-snackbar(
            :timeout="3000",
            :top="true"
            :right="true"
            :color="modalColor"
            v-model="modalMsj")
                | {{ modalText }}
                v-btn(flat dark @click.native="modalMsj = false") {{ $t('cerrar') }}
        v-dialog(v-if="modal" v-model="modal" max-width="1500" persistent)
            v-form
                v-card
                    v-card-text(v-if="preload")
                       | {{ $t('Cargando...') }}
                    template(v-else)
                        v-card-title
                            .btns.btns-right
                                v-btn(icon color="secondary" small @click="close")
                                    v-icon close
                            | {{ $t('Resultados de la red') }}&nbsp;{{ current.name }}
                        v-card-text
                            template(v-if="current.results")
                                v-layout(row wrap)
                                    v-flex(xs12 sm6)
                                        b.d-block {{ $t('Promedio de respuestas correctas: ') }}
                                        h2.my-0 {{ current.results.avg }}/5 {{ $t('preguntas') }}
                                        v-progress-linear.mb-4(:value="current.results.avg * 5" :buffer-value="(5*5)" color="green")
                                    v-flex(xs12 sm6)
                                        b.d-block {{ $t('Porcentaje de respuestas correctas: ') }}
                                        v-progress-circular.mt-2(:size="100" :value="current.results.per" color="green")
                                            h2 {{ current.results.per }}% 
                                    v-flex(xs12 sm6)
                                        b.d-block {{ $t('Total de números validados: ') }}
                                        h2 {{ current.results.numbers }}
                                    v-flex(xs12 sm6)
                                        b.d-block {{ $t('Total de números que no se validaron correctamente: ') }}
                                        h2 {{ current.results.incomplete }}
                            v-layout.mt-2(row wrap)
                                v-flex(xs12)
                                    h2 {{ $t('Promedios por cada 10 números validados') }}
                                v-flex(xs12)
                                    v-data-table(
                                        :headers='headers'
                                        :items='results'
                                        :pagination.sync='pagination'
                                        :rows-per-page-items="$root.rowsPageItems"
                                        :rows-per-page-text="$root.rowsPageText" 
                                        item-key='uuid'
                                        :total-items="total"
                                        :loading="loading"
                                    )
                                        template(slot="no-results")
                                            | {{$t('No se han encontrado resultados')}}
                                        template(slot="no-data")
                                            | {{$t('No se han encontrado resultados')}}
                                        template(slot='headers' slot-scope='props')
                                            tr
                                                th(v-for='header in props.headers' :key='header.text' :align="header.align")
                                                    span.subheading {{ $t(header.text) }}
                                        template(slot='items' slot-scope='props')
                                            tr
                                                td {{ props.item.last_date }}
                                                td 
                                                    v-progress-linear.ma-0(:value="props.item.avg * 5" :buffer-value="(props.item.max*5)" color="green")
                                                    small {{ props.item.avg }}/{{ props.item.max }}
                                                td
                                                    v-progress-circular.mt-2(:size="50" :value="props.item.per" color="green")
                                                        small {{ props.item.per }}% 
                                                td {{ props.item.numbers }}
                                                td {{ props.item.incomplete }}
</template>

<script>

import _m from 'lodash/merge'
import _ from 'lodash/debounce'
import fn from 'mixins/fn'

export default {
    mixins: [fn],
    data(){
        return {

            // Vista
            modal      : false,
            loadingBtn : false,
            preload    : false,
            loading    : false,
            pagination: {
                page: 1,
                rowsPerPage: 20
            },
            total: 0,

            // Mensajes
            modalMsj  : false,
            modalColor: '',
            modalText : '',

            // Datos
            current: {},
            results: [],
            headers: [
                {
                    text: 'Ultima validación',
                    align: 'left',
                    value: 'last_date',
                    sortable: false
                },
                {
                    text: 'Respuestas correctas',
                    align: 'left',
                    value: 'avg',
                    sortable: false
                },
                {
                    text: '% de respuestas correctas',
                    align: 'left',
                    value: 'per',
                    sortable: false
                },
                {
                    text: 'Total validados',
                    align: 'left',
                    value: 'numbers',
                    sortable: false
                },
                {
                    text: 'Total incompletos',
                    align: 'left',
                    value: 'incomplete',
                    sortable: false
                }
            ],
        }
    },
    watch: {
        pagination: {
            handler () {
                this.fetchResult()
            },
            deep: true
        }
    },
    methods: {
        async open(data){

            this.modal   = true
            this.current = _m({}, data)
            this.total   = 0 
            this.results = []

            this.pagination = {
                page: 1,
                rowsPerPage: 20
            }

            this.fetchResult()
        },
        close(){

            this.model      = _m({}, this.modelSchema)
            this.loading    = false
            this.modal      = false
            this.results    = []
        },
        fetchResult: _(function(){

            this.loading = true
            this.$Progress.start()

            let url    = '/callcenter/result'
            let params = {
                limit   : this.pagination.rowsPerPage,
                offset  : (this.pagination.page - 1) * this.pagination.rowsPerPage,
                network : this.current.uuid 
            }

            this.$api(this, (xhr) => {
                xhr.get(url, { params: params }).then((r) => {

                    let data = r.data
                    this.preload = false
                    this.loading = false
                    this.$Progress.finish()
                    
                    if(data.response){
                        this.results = data.data
                        this.total = data.total
                    }else{
                        this.results = []
                    }

                }).catch(() => {
                    
                    this.preload   = false
                    this.loading   = false
                    this.$Progress.finish()

                    this.catchError('result')
                })
            })

        }, 500)
    }
}

</script>
